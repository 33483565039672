import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AboutUsLayout from '../../components/AboutUsLayout';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import SEO from '../../components/SEO/SEO';
import AboutUsEnvironmentDesktop from '../../assets/images/about_us_enviro1_desktop.jpg';
import AboutUsEnvironmentMobile from '../../assets/images/about_us_enviro1_mobile.jpg';
import AboutUsEnvironment2Desktop from '../../assets/images/about_us_enviro2_desktop.jpg';
import AboutUsEnvironment2Mobile from '../../assets/images/about_us_enviro2_mobile.jpg';
import { superScript } from '../../lib/utils';

import './about-page.scss';

const Environment = injectIntl(({ intl, location }) => {
  const learnAboutRE100 = (
    <Link to="https://www.there100.org/" target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="pages.about.environment.company-goals.renewable-energy.learn-about-re100" />
    </Link>
  );
  const visitEnvSusWebsite = (
    <Link to="https://environment.capitalone.com/emissions-reduction" target="_blank" rel="noopener">
      <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.visit-env-sus-website" />
    </Link>
  );
  const visitCDPWebsite = (
    <Link to="https://www.cdp.net/en" target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.visit-cdp-website" />
    </Link>
  );
  const visitEnvWebsite = (
    <Link to="https://environment.capitalone.com/paper-policy" target="_blank" rel="noopener">
      <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.visit-env-sus-website" />
    </Link>
  );
  const visitWWFGFWebsite = (
    <Link to="https://forestsforward.panda.org/" target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.visit-cdp-website" />
    </Link>
  );
  const visitEnvAccWebsite = (
    <Link to="https://environment.capitalone.com/accountability-and-recognitions" target="_blank" rel="noopener">
      <FormattedMessage id="pages.about.environment.company-goals.governance.visit-env-acc-website" />
    </Link>
  );
  return (
    <AboutUsLayout intl={intl} location={location} pageName="about-us-page">
      <section className="environment-wrapper">
        <div>
          <section className="environment-content">
            <header>
              <h2 className="environment-title">
                <FormattedMessage id="pages.about.environment.title" />
              </h2>
            </header>
            <div className="environment-content-content">
              <p>
                <FormattedMessage id="pages.about.environment.text" />
              </p>
            </div>
          </section>
          <img src={AboutUsEnvironmentDesktop} alt="" className="renewable-energy-desktop-image" />
          <img src={AboutUsEnvironment2Desktop} alt="" className="environmentally-preferred-paper-desktop-image" />
          <section id="company-goals">
            <div>
              <section className="company-goals-contents">
                <header>
                  <h2 className="company-goals-title">
                    <FormattedMessage id="pages.about.environment.company-goals.title" />
                  </h2>
                </header>
                <div className="company-goals-content">
                  <img src={AboutUsEnvironmentMobile} alt="" className="renewable-energy-mobile-image" />
                  <section id="renewable-energy">
                    <header>
                      <h3>
                        <FormattedMessage id="pages.about.environment.company-goals.renewable-energy.title" />
                      </h3>
                    </header>
                    <p>
                      <FormattedMessage
                        id="pages.about.environment.company-goals.renewable-energy.paragraph1"
                        values={{ rball: superScript('rball') }}
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="pages.about.environment.company-goals.renewable-energy.paragraph2"
                        values={{ learnAboutRE100 }}
                      />
                    </p>
                  </section>
                </div>
                <section id="greenhouse-emissions">
                  <header>
                    <h3>
                      <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.title" />
                    </h3>
                  </header>
                  <p>
                    <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.paragraph1" />
                  </p>
                  <p>
                    <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.paragraph2" />
                  </p>
                  <ul>
                    <li>
                      <p>
                        <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.point1" />
                      </p>
                    </li>
                    <li>
                      <p>
                        <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.point2" />
                      </p>
                    </li>
                  </ul>
                  <p>
                    <Link to="https://environment.capitalone.com/emissions-reduction" target="_blank" rel="noopener">
                      <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.paragraph3" />
                    </Link>
                    {intl.locale === 'fr' && (
                      <FormattedMessage id="pages.about.environment.company-goals.greenhouse-emissions.link-text-part" />
                    )}
                  </p>
                  <p>
                    <FormattedMessage
                      id="pages.about.environment.company-goals.greenhouse-emissions.paragraph4"
                      values={{ visitEnvSusWebsite }}
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="pages.about.environment.company-goals.greenhouse-emissions.paragraph5"
                      values={{ visitCDPWebsite }}
                    />
                  </p>
                </section>
                <section id="environmentally-preferred-paper">
                  <header>
                    <h3>
                      <FormattedMessage id="pages.about.environment.company-goals.environmentally-preferred-paper.title" />
                    </h3>
                  </header>
                  <div className="environmentally-preferred-paper-content">
                    <img
                      src={AboutUsEnvironment2Mobile}
                      alt=""
                      className="environmentally-preferred-paper-mobile-image"
                    />
                    <p>
                      <FormattedMessage id="pages.about.environment.company-goals.environmentally-preferred-paper.paragraph1" />
                    </p>
                    <p>
                      <FormattedMessage id="pages.about.environment.company-goals.environmentally-preferred-paper.paragraph2" />
                    </p>
                    <p>
                      <FormattedMessage
                        id="pages.about.environment.company-goals.environmentally-preferred-paper.paragraph3"
                        values={{ visitEnvWebsite }}
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="pages.about.environment.company-goals.environmentally-preferred-paper.paragraph4"
                        values={{ visitWWFGFWebsite }}
                      />
                    </p>
                  </div>
                </section>
                <section id="leadership-in-energy">
                  <header>
                    <h3>
                      <FormattedMessage id="pages.about.environment.company-goals.leadership-in-energy.title" />
                    </h3>
                  </header>
                  <p>
                    <FormattedMessage id="pages.about.environment.company-goals.leadership-in-energy.paragraph1" />
                  </p>
                </section>
                <section id="governance">
                  <header>
                    <h3>
                      <FormattedMessage id="pages.about.environment.company-goals.governance.title" />
                    </h3>
                  </header>
                  <p>
                    <FormattedMessage id="pages.about.environment.company-goals.governance.paragraph1" />
                  </p>
                  <p>
                    <FormattedMessage
                      id="pages.about.environment.company-goals.governance.paragraph2"
                      values={{ visitEnvAccWebsite }}
                    />
                  </p>
                </section>
              </section>
            </div>
          </section>
        </div>
      </section>
    </AboutUsLayout>
  );
});

export default Environment;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.about.environment.page-title"
    descriptionKey="pages.about.environment.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
