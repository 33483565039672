import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DAPP_URL } from './constants';

const superScript = (scriptId) => (
  <sup>
    <FormattedMessage id={`common.superscripts.${scriptId}`} />
  </sup>
);

const callUsAnchorModalOpen = () => {
  window?.location?.href.includes('#label-acc-5') &&
    document.getElementById('section-acc-5')?.classList.contains('hidden') &&
    document.getElementById('label-acc-5').click();
};

function getContentKeyUtil(prefix) {
  return (intl, subPath) => intl.formatMessage({ id: `${prefix}.${subPath}` });
}

export { superScript, getContentKeyUtil, callUsAnchorModalOpen };
export default { superScript };

export const getNavLinks = (intl, dappBaseUrl = DAPP_URL) => {
  return [
    {
      title: intl.formatMessage({ id: 'navfooter.about.about-us' }),
      links: [
        {
          text: intl.formatMessage({ id: 'navfooter.about.about-capitalone' }),
          url: '/about/corporate-information/',
        },
        {
          text: intl.formatMessage({ id: 'navheader.link.life-and-credit.text' }),
          url: intl.formatMessage({ id: 'navheader.link.life-and-credit.url' }),
          newTab: true,
        },
        {
          text: intl.formatMessage({ id: 'navfooter.about.careers' }),
          url: 'https://www.capitalonecareers.ca/',
          newTab: true,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: 'navfooter.legal.legal' }),
      links: [
        {
          text: intl.formatMessage({ id: 'navfooter.legal.terms' }),
          url: '/about/terms-of-use/',
        },
        {
          text: intl.formatMessage({ id: 'navfooter.legal.privacy' }),
          url: '/privacypolicy/',
        },
        {
          text: intl.formatMessage({ id: 'navfooter.legal.adChoices' }),
          url: '/privacypolicy/#oba',
        },
      ],
    },
    {
      title: intl.formatMessage({ id: 'navfooter.learn.learn-more' }),
      links: [
        {
          text: intl.formatMessage({ id: 'navfooter.learn.contact-us' }),
          url: '/contact-us/capitalonecards/',
        },
        {
          text: intl.formatMessage({ id: 'navheader.link.support-centre.text' }),
          url: intl.formatMessage({ id: 'navheader.link.support-centre.url' }),
          newTab: true,
        },
        {
          text: intl.formatMessage({ id: 'navfooter.learn.mail-offer' }),
          url: `${dappBaseUrl}${intl.formatMessage({ id: 'navfooter.learn.mail-offer-link-path' })}`,
          newTab: true,
        },
        {
          text: intl.formatMessage({ id: 'navfooter.learn.accessibility' }),
          url: intl.formatMessage({ id: 'navfooter.learn.accessibility-link' }),
          newTab: true,
        },
        {
          text: intl.formatMessage({ id: 'navfooter.learn.cyber-incident' }),
          url: intl.formatMessage({ id: 'navfooter.learn.cyber-incident-link' }),
          newTab: true,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: 'navfooter.online.online-banking' }),
      links: [
        {
          text: intl.formatMessage({ id: 'navfooter.online.sign-in' }),
          url: '/sign-in/',
        },
      ],
    },
  ];
};

export const getQuickCheckNavBarLinks = (intl) => {
  return [
    {
      text: intl.formatMessage({ id: 'navfooter.legal.privacy' }),
      url: '/privacypolicy/',
    },
    {
      text: intl.formatMessage({ id: 'navfooter.legal.security' }),
      url: '/about/security/',
    },
    {
      text: intl.formatMessage({ id: 'navfooter.legal.terms' }),
      url: '/about/terms-of-use/',
    },
  ];
};
